import React from 'react';
import { useParams } from 'react-router-dom';
import { MODULES, ROUTES } from '../../../common/constants';
import PageHeader from '../../../components/PageHeader';
import CommonAudioForm from './CommonAudioForm';

const AddEditAudio = () => {
  const params = useParams();

  const podcastsBreadcrumbs = [
    { label: MODULES?.ASSETS },
    { label: MODULES?.PODCASTS, route: ROUTES?.ASSETS_PODCASTS },
    params?.id && { label: params?.id },
    { label: params?.id ? 'Edit' : 'Add' }
  ].filter(Boolean);

  return (
    <>
      <PageHeader menu={podcastsBreadcrumbs} />
      <div className="page-wrapper">
        <div className="page-wrapper-body">
          <CommonAudioForm />
        </div>
      </div>
    </>
  );
};

export default AddEditAudio;
