import { EyeFilled } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Tooltip } from 'antd';
import { ArrowClockwise, PencilSimpleLine, Trash } from 'phosphor-react';
import React, { useRef } from 'react';
import DefaultCollectionImg from '../../../assets/images/collection.png';
import {
  ASSET_CATEGORY,
  CLOUD_FRONT_CACHE_KEYS,
  MODULES,
  ROUTES,
  WORKSPACE_ROLE_LEVEL,
  WORKSPACE_ROLE_PERMISSION
} from '../../../common/constants';
import { getImageUrl } from '../../../common/utils';
import { modalContext } from '../../../components/AppComponentContainer';
import FilterBar, { useFilterBar } from '../../../components/FilterBar';
import PageHeader from '../../../components/PageHeader';
import PageList from '../../../components/PageList';
import useCheckPermission from '../../../hooks/useCheckPermission';
import CustomCard from '../../component/card/Cscard';
import { INVALIDATE_CLOUD_FRONT_CACHE } from '../../menus/graphql/Mutations';
import { DELETE_COLLECTION } from './graphql/Mutations';
import { GET_COLLECTIONS } from './graphql/Queries';

const variablesSelector = ({
  limit,
  offset,
  search,
  sortField,
  sortOrder
}) => ({
  filter: {
    skip: offset,
    limit,
    search
  },
  sort: {
    sortOn: sortField,
    sortBy: sortOrder
  }
});

const dataSelector = ({ collectionsAdmin }) => ({
  data: collectionsAdmin?.collections ?? [],
  count: collectionsAdmin?.count ?? 0
});

const Collections = ({ history }) => {
  const ref = useRef();
  const isAddEditAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.LABEL_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);

  const isViewOnly = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.LABEL_MANAGEMENT,
      allowedPermissions: [WORKSPACE_ROLE_LEVEL.VIEW]
    }
  ]);

  const isDeleteAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.LABEL_MANAGEMENT,
      allowedPermissions: [WORKSPACE_ROLE_LEVEL.DELETE]
    }
  ]);

  const [invalidateCache, { loading: isInvalidating }] = useMutation(
    INVALIDATE_CLOUD_FRONT_CACHE,
    {
      variables: { key: CLOUD_FRONT_CACHE_KEYS.OG_IMAGES }
    }
  );
  const filterProps = useFilterBar();

  const [deleteCollection] = useMutation(DELETE_COLLECTION);

  const handleShowModal = () => {
    history.push(`${ROUTES?.LABELS_COLLECTIONS}/add`);
  };
  const handlePreview = (item) => {
    if (ref.current.showPreview) {
      ref.current.showPreview({
        title: item?.title,
        url: item?.thumbnail?.url || DefaultCollectionImg,
        type: ASSET_CATEGORY.IMAGE
      });
    }
  };

  const handleEdit = (id) => {
    history.push(`${ROUTES?.LABELS_COLLECTIONS}/${id}/edit`, {
      isUpdate: true,
      imageId: id
    });
  };

  const handleDelete = (id) => {
    modalContext?.confirm({
      title: 'Are you sure, you want to delete this collection?',
      centered: true,
      okText: 'Yes',
      cancelText: 'No',
      okType: 'primary',
      onOk() {
        deleteCollection({
          variables: { id }
        })
          .then(({ errors }) => {
            if (ref?.current?.removeItem && !errors) {
              ref?.current?.removeItem(id);
            }
          })
          .catch();
      }
    });
  };

  const collectionBreadcrumbs = [
    { label: MODULES?.LABELS },
    { label: MODULES?.COLLECTIONS }
  ];

  return (
    <>
      <PageHeader
        menu={collectionBreadcrumbs}
        handleShowModal={handleShowModal}
        isAddEditAllowed={isAddEditAllowed}
      />
      <FilterBar
        {...filterProps}
        SearchProps={{ placeholder: 'Search by collection name' }}
        show={{ listModes: false }}
      >
        <Tooltip title="Update Share Preview" placement="left">
          <Button
            className="text-btn d-flex align-center justify-center"
            type="text"
            icon={<ArrowClockwise size={24} />}
            onClick={invalidateCache}
            loading={isInvalidating}
          />
        </Tooltip>
      </FilterBar>
      <PageList
        ref={ref}
        filters={filterProps.filters}
        query={GET_COLLECTIONS}
        variablesSelector={variablesSelector}
        dataSelector={dataSelector}
        limit={20}
        GridProps={{
          renderItem: (item) => {
            return (
              <CustomCard
                onClick={() => {
                  handlePreview(item);
                }}
                alt={item?.title}
                src={getImageUrl(item?.thumbnail?.url) || DefaultCollectionImg}
                blurHash={item?.thumbnail?.blurhash}
                title={`${item?.itemsCount} Episodes`}
                heading={item?.title}
                primaryOnClick={(e) => {
                  e?.stopPropagation();
                  e?.preventDefault();
                  handleEdit(item?.id);
                }}
                secondaryOnClick={(e) => {
                  e?.stopPropagation();
                  e?.preventDefault();
                  handleDelete(item?.id);
                }}
                primary={
                  isAddEditAllowed &&
                  (isViewOnly ? (
                    <>
                      <EyeFilled size={16} />
                      View
                    </>
                  ) : (
                    <>
                      <PencilSimpleLine size={16} />
                      Edit
                    </>
                  ))
                }
                secondary={
                  isDeleteAllowed && (
                    <>
                      <Trash size={16} />
                      Delete
                    </>
                  )
                }
              />
            );
          }
        }}
      />
    </>
  );
};

export default Collections;
