import React from 'react';
import { useParams } from 'react-router-dom';
import { MODULES, ROUTES } from '../../../common/constants';
import PageHeader from '../../../components/PageHeader';
import CommonImageForm from './CommonImageForm';

const AddEditImage = () => {
  const params = useParams();

  const imageBreadcrumbs = [
    { label: MODULES?.ASSETS },
    { label: MODULES?.IMAGES, route: ROUTES?.ASSETS_IMAGES },
    params?.id && { label: params?.id },
    { label: params?.id ? 'Edit' : 'Add' }
  ].filter(Boolean);

  return (
    <>
      <PageHeader menu={imageBreadcrumbs} />
      <div className="page-wrapper">
        <div className="page-wrapper-body">
          <CommonImageForm />
        </div>
      </div>
    </>
  );
};

export default AddEditImage;
