import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Select as AntdSelect,
  Button,
  Col,
  Form,
  Input,
  Row,
  Space
} from 'antd';
import { filter, map, some, uniqBy } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import {
  ASSET_CATEGORY,
  MAX_LENGTHS,
  MODULES,
  PAGE_TYPES,
  PAGE_TYPES_OPTIONS,
  REGEX,
  ROUTES,
  STATIC_PAGES,
  WORKSPACE_ROLE_LEVEL,
  WORKSPACE_ROLE_PERMISSION
} from '../../common/constants';
import { formValidatorRules } from '../../common/utils';
import LoaderComponent from '../../components/LoaderComponent';
import PageHeader from '../../components/PageHeader';
import useCheckPermission from '../../hooks/useCheckPermission';
import SelectAsset, { initialAsset } from '../assets/components/SelectAsset';
import { Switch } from '../pages/component/pageModules/moduleForms/FormInputs';
import { Select } from '../videos/components/FormInputs';
import { CREATE_MENU, UPDATE_MENU } from './graphql/Mutations';
import { GET_LANGUAGES, GET_MENU, GET_SLUGS } from './graphql/Queries';

export const MENU_POSITIONS = {
  HEADER: 'HEADER',
  FOOTER: 'FOOTER',
  BOTTOM_NAV: 'BOTTOM_NAV',
  MORE: 'MORE'
};

const MENU_TYPES = { INTERNAL: 'INTERNAL', EXTERNAL: 'EXTERNAL' };

const initialValues = {
  icons: {
    default: {
      ...initialAsset
    },
    disabled: {
      ...initialAsset
    },
    focus: {
      ...initialAsset
    },
    hover: {
      ...initialAsset
    }
  },
  position: MENU_POSITIONS.HEADER,
  showInSideMenu: true,
  type: MENU_TYPES.INTERNAL,
  internalPageType: null,
  slug: null,
  url: '',
  altTag: '',
  openInSameTab: true,
  languageArray: []
};

const getIconValue = (icon) =>
  icon
    ? {
        id: icon?.id,
        url: icon?.url
      }
    : initialAsset;

export const MENU_POSITIONS_OPTIONS = [
  { label: 'Header', value: MENU_POSITIONS.HEADER },
  { label: 'Footer', value: MENU_POSITIONS.FOOTER },
  { label: 'Bottom Nav', value: MENU_POSITIONS.BOTTOM_NAV },
  { label: 'More', value: MENU_POSITIONS.MORE }
];
const MENU_TYPES_OPTIONS = [
  { label: 'Internal', value: MENU_TYPES.INTERNAL },
  { label: 'External', value: MENU_TYPES.EXTERNAL }
];

const AddEditMenu = ({ history, match: { params }, location: { search } }) => {
  const [validationTriggered, setValidationTriggered] = useState(false);
  const [form] = Form.useForm();
  const languageArrayValue = Form?.useWatch('languageArray', form);
  const { menuId } = params;
  const isEdit = !!menuId;

  const menuBreadcrumbs = [
    { label: MODULES?.UI_CONFIGURATIONS },
    { label: MODULES?.MENUS, route: ROUTES?.UI_CONFIG_MENUS },
    isEdit && { label: menuId },
    { label: isEdit ? 'Edit' : 'Add' }
  ].filter(Boolean);

  const [
    getLanguages,
    { data: languagesData, loading: languageLoading }
  ] = useLazyQuery(GET_LANGUAGES, {
    fetchPolicy: 'network-only'
  });
  const isAssetEditAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.ASSET_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);

  const isAssetViewAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.ASSET_MANAGEMENT,
      allowedPermissions: [WORKSPACE_ROLE_LEVEL.VIEW]
    }
  ]);

  const type = Form.useWatch(['type'], form);

  const internalPageType = Form.useWatch(['internalPageType'], form);

  const [fetchMenuDetails, { loading: fetchingDetails }] = useLazyQuery(
    GET_MENU,
    {
      fetchPolicy: 'network-only'
    }
  );

  const [addUpdateMenu, { loading }] = useMutation(
    isEdit ? UPDATE_MENU : CREATE_MENU
  );

  useEffect(() => {
    (async () => {
      const {
        data: { languages }
      } = await getLanguages();
      if (languages?.languages?.length > 0) {
        const languageArray = map(languages?.languages, (item) => {
          return {
            languageCode: { label: item?.name, value: item?.code },
            name: ''
          };
        });
        if (!isEdit) {
          form?.setFieldsValue({
            languageArray
          });
        }
        if (isEdit && !!menuId) {
          fetchMenuDetails({
            variables: {
              id: menuId
            }
          })
            .then(({ data }) => {
              if (data?.menuAdmin) {
                const menu = data.menuAdmin;
                const addedMenuData = map(menu?.languageWiseMenu, (item) => {
                  return {
                    languageCode: {
                      label: item?.language?.name,
                      value: item?.language?.code
                    },
                    name: item?.name
                  };
                });
                form.setFieldsValue({
                  icons: {
                    default: getIconValue(menu?.icons?.default),
                    hover: getIconValue(menu?.icons?.hover),
                    focus: getIconValue(menu?.icons?.focus),
                    disabled: getIconValue(menu?.icons?.disabled)
                  },
                  showInSideMenu: menu?.showInSideMenu ?? true,
                  openInSameTab: menu?.openInSameTab ?? true,
                  altTag: menu?.altTag ?? '',
                  type: menu?.type ?? MENU_TYPES.INTERNAL,
                  internalPageType: menu?.internalPageType ?? null,
                  ...(menu?.type === MENU_TYPES.INTERNAL && {
                    ...(menu?.internalPageType === PAGE_TYPES.STATIC
                      ? { slug: menu?.url }
                      : {
                          slug: menu?.url
                            ? { label: `/${menu?.url}`, value: menu?.url }
                            : null
                        })
                  }),
                  url: `/${menu?.url}` ?? '',
                  position: MENU_POSITIONS.HEADER,
                  languageArray: uniqBy(
                    [...addedMenuData, ...languageArray],
                    (item) => item?.languageCode?.value
                  )
                });
              }
            })
            .catch();
        } else {
          const query = new URLSearchParams(search);
          const position = query?.get('position') ?? MENU_POSITIONS.HEADER;
          form.setFieldValue('position', position);
        }
      }
    })();
  }, [isEdit, menuId, form, fetchMenuDetails, search]);

  const hasAddedOneName = some(
    languageArrayValue,
    (item) => item?.name?.length
  );

  const handleSubmit = ({
    icons,
    slug: _slug,
    languageArray = [],
    ...rest
  }) => {
    if (!hasAddedOneName) {
      setValidationTriggered(true);
      return;
    }
    const payload = {
      ...rest,
      name: map(
        filter(languageArray, (item) => item?.name?.length),
        (item) => {
          return {
            languageCode: item?.languageCode?.value,
            name: item?.name
          };
        }
      ),
      icons: icons
        ? Object.fromEntries(
            Object.entries(icons).map(([key, value]) => [
              key,
              value?.id || null
            ])
          )
        : null,
      ...(rest.type === MENU_TYPES.INTERNAL && {
        url: rest.url?.startsWith('/') ? rest.url?.substring(1) : rest.url
      }),
      ...(!isEdit && {
        order: 1
      })
    };
    addUpdateMenu({
      variables: {
        data: payload,
        ...(isEdit && { id: menuId })
      }
    }).then(() => {
      history.push(ROUTES?.UI_CONFIG_MENUS);
    });
  };

  const handleCancel = () => {
    history.push(ROUTES?.UI_CONFIG_MENUS);
  };

  const isAddEditAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.UI_CONFIG_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL?.DELETE
      ]
    }
  ]);

  const isViewOnly = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.UI_CONFIG_MANAGEMENT,
      allowedPermissions: [WORKSPACE_ROLE_LEVEL.VIEW]
    }
  ]);

  const icon = useMemo(
    () => (
      <SelectAsset
        disabled={isViewOnly}
        modalTitle="Select Icon"
        categoryKey={ASSET_CATEGORY.ICON}
        btnText="Icon"
        dataSelector={({ id, url }) => ({
          id,
          url
        })}
        isAssetEditAllowed={isAssetEditAllowed}
        isAssetViewAllowed={isAssetViewAllowed}
      />
    ),
    [isViewOnly, isAssetEditAllowed]
  );

  return (
    <>
      <PageHeader menu={menuBreadcrumbs} />
      <div className="page-wrapper">
        <div className="page-wrapper-body">
          <Form
            form={form}
            className="add-edit-form"
            layout="vertical"
            initialValues={initialValues}
            onFinish={handleSubmit}
            onFinishFailed={() => setValidationTriggered(true)}
            disabled={isViewOnly || fetchingDetails}
          >
            {languageLoading ? (
              <LoaderComponent setHeight={10} size="small" />
            ) : (
              <Form.List name="languageArray">
                {(fields) => (
                  <Row gutter={[16, 0]}>
                    {fields?.map((field) => (
                      <Col
                        xs={24}
                        sm={24}
                        md={24}
                        lg={12}
                        xl={12}
                        xxl={8}
                        key={field?.key}
                      >
                        <Form.Item
                          label="Language Code"
                          hidden
                          name={[field?.name, 'languageCode']}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          required={!hasAddedOneName}
                          label={`Name (${
                            languagesData?.languages?.languages?.[field?.key]
                              ?.name
                          })`}
                          className={`${
                            !hasAddedOneName && validationTriggered
                              ? 'mb-8'
                              : ''
                          }`}
                          name={[field?.name, 'name']}
                          rules={[
                            formValidatorRules?.maxLength(MAX_LENGTHS.CTA),
                            {
                              validator(rule, value) {
                                if (value?.length > 0 && !value.trim()) {
                                  return Promise?.reject(
                                    new Error('Please enter name!')
                                  );
                                }
                                return Promise?.resolve();
                              }
                            }
                          ]}
                        >
                          <Input placeholder="Enter name" />
                        </Form.Item>
                      </Col>
                    ))}
                  </Row>
                )}
              </Form.List>
            )}
            {!hasAddedOneName && validationTriggered && !isEdit && (
              <span className="site-result-demo-error-icon">
                Please enter at least one language menu name!
              </span>
            )}
            {!isEdit && (
              <>
                <Form.Item
                  label="Position"
                  name="position"
                  rules={[
                    {
                      required: true,
                      message: 'Please select position!'
                    }
                  ]}
                >
                  <AntdSelect
                    options={MENU_POSITIONS_OPTIONS}
                    placeholder="Select position"
                  />
                </Form.Item>
              </>
            )}
            <Form.Item
              label="Navigation"
              name="type"
              rules={[
                {
                  required: true,
                  message: 'Please select type!'
                }
              ]}
            >
              <AntdSelect
                options={MENU_TYPES_OPTIONS}
                placeholder="Select type"
                onChange={(value) => {
                  form.setFieldValue(
                    'openInSameTab',
                    value === MENU_TYPES.INTERNAL
                  );
                  form.resetFields(['internalPageType', 'slug', 'url']);
                }}
              />
            </Form.Item>
            {type === MENU_TYPES.INTERNAL && (
              <>
                <Form.Item
                  label="Page Type"
                  name="internalPageType"
                  rules={[
                    {
                      required: type === MENU_TYPES.INTERNAL,
                      message: 'Please select page type!'
                    }
                  ]}
                >
                  <AntdSelect
                    options={PAGE_TYPES_OPTIONS}
                    placeholder="Select page type"
                    onChange={() => {
                      form.resetFields(['slug', 'url']);
                    }}
                  />
                </Form.Item>
                {internalPageType && (
                  <>
                    {internalPageType === PAGE_TYPES.STATIC ? (
                      <Form.Item
                        label="Page"
                        name="slug"
                        rules={[
                          {
                            required: type === MENU_TYPES.INTERNAL,
                            message: 'Please select page!'
                          }
                        ]}
                      >
                        <AntdSelect
                          options={STATIC_PAGES}
                          onChange={(value) => {
                            form.setFieldValue('url', `/${value}`);
                            form.validateFields(['url']);
                          }}
                        />
                      </Form.Item>
                    ) : (
                      <Form.Item
                        label="Page"
                        name="slug"
                        rules={[
                          {
                            required: type === MENU_TYPES.INTERNAL,
                            message: 'Please select page!'
                          }
                        ]}
                      >
                        <Select
                          placeholder="Select"
                          query={GET_SLUGS}
                          variablesSelector={(filters) => ({
                            filter: {
                              ...filters,
                              pageType: internalPageType
                            }
                          })}
                          dataSelector={(data) => {
                            return (
                              data?.slugs?.slugs?.map(({ slug }) => ({
                                label: `/${slug}`,
                                value: slug
                              })) ?? []
                            );
                          }}
                          keys={{
                            data: 'slugs',
                            records: 'slugs',
                            count: 'count'
                          }}
                          onChange={({ value }) => {
                            form.setFieldValue('url', `/${value}`);
                            form.validateFields(['url']);
                          }}
                        />
                      </Form.Item>
                    )}
                  </>
                )}
              </>
            )}
            <Form.Item
              label="URL"
              name="url"
              rules={[
                {
                  required: true,
                  message: 'Please enter URL!'
                },
                {
                  async validator(_, value) {
                    if (
                      type === MENU_TYPES.EXTERNAL &&
                      value &&
                      !REGEX?.WEB_URL?.test(value)
                    ) {
                      return Promise?.reject(
                        new Error('Should be a valid URL')
                      );
                    }
                    return Promise?.resolve();
                  }
                }
              ]}
            >
              <Input
                placeholder="Enter url"
                readOnly={type === MENU_TYPES.INTERNAL}
                disabled={type === MENU_TYPES.INTERNAL}
              />
            </Form.Item>
            <Form.Item
              label="Alt Tag"
              name="altTag"
              rules={[formValidatorRules?.maxLength(MAX_LENGTHS.FORM_INPUT)]}
            >
              <Input placeholder="Enter alt tag" />
            </Form.Item>
            <Form.Item label="Default Icon" name={['icons', 'default']}>
              {icon}
            </Form.Item>
            <Form.Item label="Hover Icon" name={['icons', 'hover']}>
              {icon}
            </Form.Item>
            <Form.Item label="Focus Icon" name={['icons', 'focus']}>
              {icon}
            </Form.Item>
            <Form.Item label="Disabled Icon" name={['icons', 'disabled']}>
              {icon}
            </Form.Item>
            <Form.Item
              className="m-0"
              name="showInSideMenu"
              valuePropName="checked"
            >
              <Switch label="Show In Side Menu" />
            </Form.Item>
            <Form.Item
              className="m-0"
              name="openInSameTab"
              valuePropName="checked"
            >
              <Switch label="Open In Same Tab" />
            </Form.Item>
            <div className="d-flex button-section mb-8 mt-8">
              <Space>
                {isAddEditAllowed && (
                  <Button
                    disabled={loading || fetchingDetails}
                    loading={loading}
                    type="text"
                    htmlType="submit"
                    className="text-btn mr-8"
                    size="middle"
                  >
                    Save
                  </Button>
                )}

                <Button
                  disabled={loading}
                  type="text"
                  className="text-btn2"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </Space>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default AddEditMenu;
